@import "froala-editor/css/froala_style.css";

.cms-hide {
    display : none !important;
}
.link-container {
	display : block;
}
.cms-twig-traceback {
	display : none;
}
.cms-twig-error,
.cms-twig-info {
	--alert-color    : 63% 0.22 32.01;
	padding          : 0.625rem;
	color            : oklch(var(--alert-color));
	border           : 1px solid oklch(var(--alert-color));
	background-color : oklch(var(--alert-color) / 0.1);
	border-radius    : 5px;
	display          : grid;
	gap              : 0.5rem;
	word-wrap        : anywhere;
	text-wrap        : wrap;
}
.cms-twig-info {
	--alert-color : 65% 0.14 253.93;
}
body>.cms-twig-error {
	margin : 0.625rem;
}
.cms-video-embed {
	width        : 100% !important;
	height       : auto !important;
	aspect-ratio : 16 / 9;
}

@import "pagination";
